/*
 * @Author: PoJun
 * @Date: 2023-08-08 11:03:29
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-08 15:48:13
 * @Message: Nothing
 */
import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);
// 静态路由
const router = new VueRouter({
    routes: [
        {
            path: "/",
            component: () => import("@/layout/page.vue"),
            children: [
                {
                    path: "/home",
                    component: () => import("@/views/home/index.vue"),
                    meta: { title: "诚唐科技" },
                },
                {
                    path: "/product",
                    component: () => import("@/views/product/index.vue"),
                    meta: { title: "项目展示" },
                },
                {
                    path: "/about",
                    component: () => import("@/views/about/index.vue"),
                    meta: { title: "关于我们" },
                },
                {
                    path: "/contact",
                    component: () => import("@/views/contact/index.vue"),
                    meta: { title: "联系我们" },
                },
                {
                    path: "/faq",
                    component: () => import("@/views/faq/index.vue"),
                    meta: { title: "问题解答" },
                },
            ],
            redirect: "/home",
        },
        {
            path: "/Page404",
            component: () => import("@/views/error/404.vue"),
            meta: { title: "404" },
        },
        {
            path: "*",
            redirect: "/Page404",
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach(async (to, from, next) => {
    NProgress.start();
    if (to.meta?.title) {
        document.title = to.meta.title; // 某些详情页面不需要修改title
    }

    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
