/*
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-08 15:28:24
 * @Message: Nothing
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// 导入图标css
import "./assets/fontawesome/css/all.min.css";

import AOS from "aos";
import "aos/dist/aos.css";

Vue.use(AOS);

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
Viewer.setDefaults();

Vue.config.productionTip = false;

new Vue({
    mounted() {
        AOS.init();
    },
    router,
    render: h => h(App),
}).$mount("#app");
