<!--
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-14 10:01:07
 * @Message: Nothing
-->
<template>
    <router-view />
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
@import "./assets/css/animate.css";
@import "./assets/css/style.css";
#app {
    overflow: hidden;
}
</style>
